import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideCharacters'
})
export class HideCharactersPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return args[0].repeat(value.toString().length - args[1]) +
      value.toString().slice(-args[1]);
  }

}
